// atoms
import NoPaddingLabelSelect from "@/components/atoms/VSelect/NoPaddingLabelSelect";
import MultipleSelect from "@/components/atoms/VSelect/MultipleSelect";
import PaddingGrayButton from "@/components/atoms/VButton/PaddingGrayButton";
// organisms
import LoadingSpinner from "@/components/organisms/LoadingSpinner/LoadingSpinner";
import SnackBar from "@/components/organisms/SnackBar/SnackBar";
// calculate
import SalesDeadLineMainTable from "@/components/organisms/FilterList/salesReleaseDeadLineList.vue";
import header from "@/assets/table/sales/headersDeadlineSales";
import numFilterDialog from "@/components/organisms/VDialog/numFilterDialog";
import { mapGetters } from "vuex";

export default {
  components: {
    NoPaddingLabelSelect,
    PaddingGrayButton,
    LoadingSpinner,
    MultipleSelect,
    SalesDeadLineMainTable,
    numFilterDialog,
    SnackBar,
  },
  data() {
    return {
      header,
      totalArea: [
        "checkbox",
        "year",
        "month",
        "kind",
        "season",
        "order",
        "buyer",
        "item",
        "placeForward",
      ],
    };
  },
  computed: {
    ...mapGetters({
      releaseList: "getsalesEndList",
      calculateTotal: "getCalculateTotal",
    }),
    selected: {
      get() {
        return this.$store.state.selected;
      },
      set(data) {
        this.$store.state.selected = data;
      },
    },

    avgUnitPrice() {
      return this.calculateTotal.salesTotalAmount &&
        this.calculateTotal.salesQuantity
        ? parseFloat(
            (
              this.calculateTotal.salesTotalAmount /
              this.calculateTotal.salesQuantity
            ).toFixed(2),
          )
        : 0;
    },
  },
  methods: {
    confirmDialogOpen(kind) {
      this.setCheckSelected();
      if (kind === "cancel" && !this.checkSelected()) {
        this.$store.commit("setSnackBar", `마감 취소 할 건을 선택해주세요.`);
        return;
      }
      if (
        confirm(
          `${this.$store.state.selected.length} 건을 ${
            this.$store.state.auth_grade >= 2 ? "마감 취소" : "마감 취소 요청"
          } 하시겠습니까?`,
        )
      ) {
        this.$store.dispatch("TASK_SELECT_SALESCAC_SAVE", kind);
      }
    },
    checkSelected() {
      return this.$store.state.selected.length ? true : false;
    },
    setCheckSelected() {
      const selected = [];
      this.releaseList.forEach((x) => {
        if (x.checkBox) selected.push(x);
      });
      this.$store.commit("setSelected", selected);
    },
  },
  async created() {
    this.$store.commit("setSelected");

    const promises = [];

    // this.$store.dispatch("SET_CLIENTLIST");
    // this.$store.dispatch("SET_ITEMLIST");

    this.$store.dispatch("SET_API_LOADING", false);
    // });
  },
  // sockets: {
  //   async sales(data) {
  //     if (this.$store.state.auth_company === data.company)
  //       await this.$store.dispatch("GET_RELEASELIST", {
  //         kind: "salesdeadline",
  //       });
  //   },
  // },
};
